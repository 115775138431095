import { takeLatest, put, call, delay, all, select } from 'redux-saga/effects';
import get from 'lodash/get';
import find from 'lodash/find';
import { ARTIST_TYPE_ID, TP } from 'constants/index';
import { MEDIA_ATTRIBUTES_TYPES_IDS, PRODUCTION_DOCUMENT_TYPES, VIDEO_ATTRIBUTE_TYPES_IDS } from 'constants/deprecate';
import { getOrgVenuesAction } from 'containers/Organizations/actions';
import {
  getArtistNamesAction,
  uploadSeveralMediaAction,
  uploadProductionDocumentsAction,
  updateProductionSynopsisDocumentsAction,
  createDigitalEventAction,
  updateProductionAction,
  deleteDigitalEventAction,
  getLiveEventsStepDataAction,
  getProductionDatesAction,
  getProductionCastAction,
  getArtistProductionDatesAction,
  getDigitalEventsStepDataAction,
  getProductionLivestreamsAction,
  getProductionVideoOnDemandAction,
  getVideoCostTypesAction,
  getMediaStepDataAction,
  getProductionSinopsisAction,
  getMediaAttributes,
  getProductionSynopsisAction,
  getReviewsAction,
  getProductionDescriptionsAction,
  getProductionBookletsAction,
  deleteCastAction,
  getWorkCastCrewAction,
  setProductionWorksAction,
  getProductionDatesV1Action,
  getProductionCalendarDatesAction,
  getCastAndCrewAction,
} from './actions';
// eslint-disable-next-line import/no-cycle
import * as API from '../../utils/API';
import endpointConstants, {
  PRODUCTION_CASTS,
  PRODUCTION_DATES,
  VENUES,
  PROFILES_ENDPOINT,
  MEDIA,
  MEDIA_ATTRIBUTES,
  PRODUCTION_DOCUMENTS,
  PRIVACY_OPTIONS,
} from '../../constants/endpointConstants';
import { enqueueSnackbarAction, getPrivacyOptionsAction } from '../App/actions';
import { formatResponseToEntity } from '../../utils/crudUtils';
import { getWholeListSaga } from '../App/saga';
import {
  EDITING_PRODUCTION_ENTITY_TYPES,
  LIVESTREAM_REQUEST_PARAMS,
  VALID_ENTITY_FILTER,
  VOD_REQUEST_PARAMS,
} from './constants';
import { i18n } from '../../src/i18n';
import { selectProductionWorks } from './selectors';

const errorMessageConverter = error => error?.responseText || error?.responseText?.message || error?.message;

export function* fetchArtistNames({ payload, serverCookies }) {
  try {
    yield delay(300);
    const response = yield call(
      API.searchProfiles,
      ARTIST_TYPE_ID,
      payload?.query,
      payload?.exclude_id,
      {},
      {
        validation_status: VALID_ENTITY_FILTER,
        as_edit: true,
      },
      null,
      serverCookies,
    );
    yield put(getArtistNamesAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getArtistNamesAction.failure(errorMessageConverter(err)));
  } finally {
    yield put(getArtistNamesAction.fulfill());
  }
}

function* handleSagaError(err, errMsgPrefix, action) {
  const errorMessage = errorMessageConverter(err);
  yield put(
    enqueueSnackbarAction({
      message: i18n.t(errMsgPrefix, { error: errorMessage }),
      options: {
        variant: 'error',
      },
    }),
  );
  yield put(action.failure(errorMessage));
}

export function* uploadMedia({ payload, serverCookies }) {
  try {
    const data = get(payload, 'data', []);
    const response = yield all(
      data.map(media => call(API.create, endpointConstants.MEDIA, media?.data, media?.file, serverCookies)),
    );
    yield put(uploadSeveralMediaAction.success(response?.map(r => r?.data)));
    const callback = payload?.callback;
    if (callback && typeof callback === 'function') payload.callback();
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_ADDING_IMAGES`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    handleSagaError(err, `${TP}.FN_ERROR_ADDING_IMAGES`, uploadSeveralMediaAction);
  }
}

export function* uploadDocuments({ payload, serverCookies }) {
  try {
    const data = get(payload, 'data', []);
    const files = get(payload, 'files', {});
    const id = get(payload, 'id');
    const response = yield all(
      data.map(document => {
        const file = document?.file?.data ? files[document?.file?.data] : null;
        if (id) {
          return call(
            API.updateOne,
            payload?.apiUrl || endpointConstants.PRODUCTION_DOCUMENTS,
            id,
            document,
            file && { [document?.file?.data.slice(1)]: file },
            serverCookies,
          );
        }
        return call(
          API.create,
          payload?.apiUrl || endpointConstants.PRODUCTION_DOCUMENTS,
          document,
          file && { [document?.file?.data.slice(1)]: file },
          serverCookies,
        );
      }),
    );
    yield put(uploadProductionDocumentsAction.success(response?.map(r => r?.data)));
    const callback = payload?.callback;
    if (callback && typeof callback === 'function') payload.callback(response?.map(r => r?.data));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_ADDING_FILES`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    handleSagaError(err, `${TP}.FN_ERROR_ADDING_FILES`, uploadProductionDocumentsAction);
  }
}

export function* updateSynopsis({ payload, serverCookies }) {
  try {
    const created = get(payload, 'createdDocuments', []);
    const updated = get(payload, 'updatedDocuments', []);
    const deleted = get(payload, 'deletedDocuments', []);
    yield all([
      ...created.map(file => call(API.create, endpointConstants.PRODUCTION_DOCUMENTS, file, null, serverCookies)),
      ...updated.map(file =>
        call(API.updateOne, endpointConstants.PRODUCTION_DOCUMENTS, file?.id, file, null, serverCookies),
      ),
      ...deleted.map(id => call(API.deleteOne, endpointConstants.PRODUCTION_DOCUMENTS, id, {}, serverCookies)),
    ]);
    yield put(updateProductionSynopsisDocumentsAction.success());
    const callback = payload?.callback;
    if (callback && typeof callback === 'function') payload.callback();
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_SYNOPSIS`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    handleSagaError(err, `${TP}.FN_ERROR_UPDATING_SYNOPSIS`, updateProductionSynopsisDocumentsAction);
  }
}

function* updateProductionTypes({ productionId, types, message }, serverCookies) {
  try {
    const response = yield call(
      API.updateOne,
      endpointConstants.PRODUCTIONS,
      productionId,
      {
        performanceTypes: types || [],
      },
      null,
      serverCookies,
    );
    yield put(updateProductionAction.success(formatResponseToEntity(response)));
    yield put(
      enqueueSnackbarAction({
        message,
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    handleSagaError(err, `${TP}.FN_ERROR_UPDATING_PRODUCTION`, updateProductionAction);
  }
}

export function* createDigitalEvent({ payload, serverCookies }) {
  try {
    const { params, files, callback, listLength, production, typeId, typeName } = payload;
    const response = yield call(API.create, endpointConstants.MEDIA, params, files, serverCookies);
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_CREATION`, { name: typeName }),
        options: {
          variant: 'success',
        },
      }),
    );
    if (!listLength && !find(production?.performanceTypes, { id: typeId })) {
      yield call(
        updateProductionTypes,
        {
          productionId: production?.id,
          types: [...(production?.performanceTypes || []), { id: typeId }],
          message: i18n.t(`${TP}.FN_SUCCESS_MARK_PRODUCTION`, { name: typeName }),
        },
        serverCookies,
      );
    }
    yield put(createDigitalEventAction.success({ entity: response?.data, typeId }));
    if (callback && typeof callback === 'function') payload.callback();
  } catch (err) {
    handleSagaError(err, `${TP}.FN_ERROR_CREATING_DATA`, createDigitalEventAction);
  }
}

export function* deleteDigitalEvent({ payload, serverCookies }) {
  try {
    const { id, callback, listLength, production, typeId, typeName } = payload;
    yield call(API.deleteOne, endpointConstants.MEDIA, id, {}, serverCookies);
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_DELETION`, { name: typeName }),
        options: {
          variant: 'success',
        },
      }),
    );
    if (listLength === 1) {
      yield call(
        updateProductionTypes,
        {
          productionId: production?.id,
          types: production?.performanceTypes?.filter(type => type?.id !== typeId),
          message: i18n.t(`${TP}.FN_SUCCESS_UNCHECK`, { name: typeName }),
        },
        serverCookies,
      );
    }
    yield put(deleteDigitalEventAction.success({ id, typeId }));
    if (callback && typeof callback === 'function') payload.callback();
  } catch (err) {
    handleSagaError(err, `${TP}.FN_ERROR_CREATING_DATA`, deleteDigitalEventAction);
  }
}

export function* getLiveEventsStep({ payload, serverCookies }) {
  try {
    const { entityType, entityId, productionId } = payload;
    yield call(getWholeListSaga, {
      payload: {
        endpoint: PRODUCTION_DATES(productionId),
        sagaRoutine: getProductionDatesAction,
        params: { queryParams: { as_edit: true } },
      },
      serverCookies,
    });
    yield call(getWholeListSaga, {
      payload: {
        endpoint: PRODUCTION_CASTS(productionId),
        sagaRoutine: getProductionCastAction,
        params: { queryParams: { as_edit: true } },
      },
      serverCookies,
    });
    switch (entityType) {
      case EDITING_PRODUCTION_ENTITY_TYPES.COMPANY:
        yield call(getWholeListSaga, {
          payload: {
            endpoint: VENUES,
            sagaRoutine: getOrgVenuesAction,
            params: { queryParams: { company_id: entityId, validation_status: VALID_ENTITY_FILTER } },
          },
          serverCookies,
        });
        break;
      case EDITING_PRODUCTION_ENTITY_TYPES.ARTIST:
        yield call(getWholeListSaga, {
          payload: {
            endpoint: `${PROFILES_ENDPOINT}/productions/dates`,
            sagaRoutine: getArtistProductionDatesAction,
            params: {
              queryParams: {
                production_id: productionId,
                id: entityId,
              },
            },
          },
          serverCookies,
        });
        break;
      case EDITING_PRODUCTION_ENTITY_TYPES.FESTIVAL:
        yield call(getWholeListSaga, {
          payload: {
            endpoint: VENUES,
            sagaRoutine: getOrgVenuesAction,
            params: { queryParams: { festival_id: entityId, validation_status: VALID_ENTITY_FILTER } },
          },
          serverCookies,
        });
        break;
      default:
        break;
    }
    yield put(getLiveEventsStepDataAction.success());
  } catch (err) {
    handleSagaError(err, `Error: `, getLiveEventsStepDataAction);
  }
}

export function* getDigitalEventsStep({ payload, serverCookies }) {
  try {
    const { productionId } = payload;
    yield call(getWholeListSaga, {
      payload: {
        endpoint: PRODUCTION_DATES(productionId),
        sagaRoutine: getProductionDatesAction,
        params: { queryParams: { as_edit: true } },
      },
      serverCookies,
    });
    yield call(getWholeListSaga, {
      payload: {
        endpoint: MEDIA,
        sagaRoutine: getProductionLivestreamsAction,
        params: {
          queryParams: {
            media_tag_id: productionId,
            ...LIVESTREAM_REQUEST_PARAMS,
          },
        },
      },
      serverCookies,
    });
    yield call(getWholeListSaga, {
      payload: {
        endpoint: MEDIA,
        sagaRoutine: getProductionVideoOnDemandAction,
        params: {
          queryParams: {
            media_tag_id: productionId,
            ...VOD_REQUEST_PARAMS,
          },
        },
      },
      serverCookies,
    });
    yield call(getWholeListSaga, {
      payload: {
        endpoint: MEDIA_ATTRIBUTES,
        sagaRoutine: getVideoCostTypesAction,
        params: {
          queryParams: {
            attribute_type_id: VIDEO_ATTRIBUTE_TYPES_IDS.COST_TYPE,
          },
        },
      },
      serverCookies,
    });
    yield put(getDigitalEventsStepDataAction.success());
  } catch (err) {
    handleSagaError(err, `Error: `, getDigitalEventsStepDataAction);
  }
}

export function* getMediaStep({ payload, serverCookies }) {
  try {
    const { productionId } = payload;
    yield call(getWholeListSaga, {
      payload: {
        endpoint: PRODUCTION_DOCUMENTS,
        sagaRoutine: getProductionSinopsisAction,
        params: {
          queryParams: {
            production_id: productionId,
            document_type: [
              PRODUCTION_DOCUMENT_TYPES.SYNOPSIS,
              PRODUCTION_DOCUMENT_TYPES.BOOKLET,
              PRODUCTION_DOCUMENT_TYPES.ACT,
            ],
          },
        },
      },
      serverCookies,
    });
    yield call(getWholeListSaga, {
      payload: {
        endpoint: PRIVACY_OPTIONS,
        sagaRoutine: getPrivacyOptionsAction,
        params: {},
      },
      serverCookies,
    });
    yield call(getWholeListSaga, {
      payload: {
        endpoint: MEDIA_ATTRIBUTES,
        sagaRoutine: getMediaAttributes,
        params: {
          queryParams: {
            attribute_type_id: [MEDIA_ATTRIBUTES_TYPES_IDS.IMAGE, MEDIA_ATTRIBUTES_TYPES_IDS.VIDEO],
          },
        },
      },
      serverCookies,
    });
    yield put(getMediaStepDataAction.success());
  } catch (err) {
    handleSagaError(err, `Error: `, getMediaStepDataAction);
  }
}

export function* fetchReviews({ payload, serverCookies }) {
  try {
    const { url, pagination, query } = payload;
    const response = yield call(API.getReviewsV1, url, pagination, query ?? {}, serverCookies);
    yield put(getReviewsAction.success(get(response, 'data')));
  } catch (err) {
    handleSagaError(err, `Error: `, getReviewsAction);
  }
}

export function* fetchProductionDescriptions({ payload, serverCookies }) {
  try {
    const { query, url } = payload;
    const response = yield call(API.getProductionDescriptionList, url, query, serverCookies);
    yield put(getProductionDescriptionsAction.success(get(response, 'data', [])));
  } catch (err) {
    handleSagaError(err, `Error: `, getProductionDescriptionsAction);
  } finally {
    yield put(getProductionDescriptionsAction.fulfill());
  }
}

export function* fetchProductionBooklets({ payload, serverCookies }) {
  try {
    const { query, url } = payload;
    const response = yield call(API.getProductionBookletList, url, query, serverCookies);
    yield put(getProductionBookletsAction.success(get(response, 'data', [])));
  } catch (err) {
    handleSagaError(err, `Error: `, getProductionBookletsAction);
  } finally {
    yield put(getProductionBookletsAction.fulfill());
  }
}

export function* deleteCast({ payload, serverCookies }) {
  try {
    yield call(API.deleteOne, `productions/${payload?.productionId}/contributions`, payload?.castId, {}, serverCookies);
    yield put(deleteCastAction.success());
    yield put(
      enqueueSnackbarAction({
        message: 'Deleted Succesfully',
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    handleSagaError(err, `Error: `, deleteCastAction);
  }
}
export function* fetchProductionDatesV1({ payload, serverCookies }) {
  try {
    const response = yield call(
      API.getProductionDatesV1,
      payload.pagination,
      payload?.id,
      get(payload, 'query', {}),
      serverCookies,
    );
    yield put(getProductionDatesV1Action.success(get(response, 'data')));
  } catch (err) {
    handleSagaError(err, `Error: `, getProductionDatesV1Action);
  } finally {
    yield put(getProductionDatesV1Action.fulfill());
  }
}

export function* fetchCastAndCrew({ payload, serverCookies }) {
  try {
    const { query, production_id } = payload;
    const response = yield call(API.getCastAndCrewList, query, production_id, serverCookies);
    yield put(getCastAndCrewAction.success(get(response, 'data', [])));
  } catch (err) {
    handleSagaError(err, `Error: `, getCastAndCrewAction);
  } finally {
    yield put(getCastAndCrewAction.fulfill());
  }
}

export function* getWorkCastCrew({ payload, serverCookies }) {
  try {
    const response = yield call(
      API.getWorkCastCrew,
      `production/${payload?.productionId}/work/cast-crew`,
      payload,
      serverCookies,
    );
    let works = yield select(selectProductionWorks());

    if (+payload?.workId === -1) {
      works = [...works, { id: -1, work: { id: -1 } }];
    }

    const worksWithCastCrew = works?.map(item => {
      if (item?.work?.id === +payload?.workId) {
        item.crew = response?.data?.crew;
        item.cast = response?.data?.cast;
        item.ensembles = response?.data?.ensembles;
      }
      return { ...item };
    });
    yield put(setProductionWorksAction(worksWithCastCrew));
    yield put(getWorkCastCrewAction.success());
  } catch (err) {
    handleSagaError(err, `Error: `, getWorkCastCrewAction);
  }
}

export function* fetchProductionCalendarDates({ payload, serverCookies }) {
  try {
    const response = yield call(
      API.getProductionCalendarDates,
      payload.pagination,
      payload?.id,
      get(payload, 'query', {}),
      serverCookies,
    );
    yield put(getProductionCalendarDatesAction.success(get(response, 'data')));
  } catch (err) {
    handleSagaError(err, `Error: `, getProductionCalendarDatesAction);
    yield put(getCastAndCrewAction.fulfill());
  }
}

export function* fetchProductionSynopsis({ payload, serverCookies }) {
  try {
    const { query, production_id } = payload;
    const response = yield call(API.getProductionSynopsisList, query, production_id, serverCookies);
    yield put(getProductionSynopsisAction.success(get(response, 'data', [])));
  } catch (err) {
    handleSagaError(err, `Error: `, getProductionSynopsisAction);
  } finally {
    yield put(getProductionCalendarDatesAction.fulfill());
    yield put(getProductionSynopsisAction.fulfill());
  }
}

export default function* ProductionUpdateSaga() {
  yield takeLatest(getArtistNamesAction.TRIGGER, fetchArtistNames);
  yield takeLatest(uploadSeveralMediaAction.TRIGGER, uploadMedia);
  yield takeLatest(uploadProductionDocumentsAction.TRIGGER, uploadDocuments);
  yield takeLatest(updateProductionSynopsisDocumentsAction.TRIGGER, updateSynopsis);
  yield takeLatest(createDigitalEventAction.TRIGGER, createDigitalEvent);
  yield takeLatest(deleteDigitalEventAction.TRIGGER, deleteDigitalEvent);
  yield takeLatest(getLiveEventsStepDataAction.TRIGGER, getLiveEventsStep);
  yield takeLatest(getDigitalEventsStepDataAction.TRIGGER, getDigitalEventsStep);
  yield takeLatest(getMediaStepDataAction.TRIGGER, getMediaStep);
  yield takeLatest(getReviewsAction.TRIGGER, fetchReviews);
  yield takeLatest(getProductionDescriptionsAction.TRIGGER, fetchProductionDescriptions);
  yield takeLatest(getProductionSynopsisAction.TRIGGER, fetchProductionSynopsis);
  yield takeLatest(getProductionBookletsAction.TRIGGER, fetchProductionBooklets);
  yield takeLatest(deleteCastAction.TRIGGER, deleteCast);
  yield takeLatest(getWorkCastCrewAction.TRIGGER, getWorkCastCrew);
  yield takeLatest(getProductionDatesV1Action.TRIGGER, fetchProductionDatesV1);
  yield takeLatest(getProductionCalendarDatesAction.TRIGGER, fetchProductionCalendarDates);
  yield takeLatest(getCastAndCrewAction.TRIGGER, fetchCastAndCrew);
}
