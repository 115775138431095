import React from 'react';
import dynamic from 'next/dynamic';
import { BASE_PAGE_ROUTES } from 'constants/index';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import { usePaywallContext } from 'utils/hooks/usePaywallContext';

const PaywallModal = dynamic(() => import('components/Globals/FootPrintTracking/PaywallModal/PaywallModal'));

const PaywallModalWrapper = ({ children }) => {
  const { isLoggedIn } = useAppContext();
  const { pathname } = usePageContext();
  const { showPaywall } = usePaywallContext();

  const shouldShowPaywall = !isLoggedIn || (showPaywall && isLoggedIn);
  const isExcludePaywall = [BASE_PAGE_ROUTES.LOGIN, BASE_PAGE_ROUTES.REGISTER].some(route => pathname.includes(route));
  if (isExcludePaywall) {
    return children;
  }
  return (
    <>
      {children}
      {shouldShowPaywall && <PaywallModal />}
    </>
  );
};

export default PaywallModalWrapper;
