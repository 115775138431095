import { createRoutine } from 'redux-saga-routines';

import {
  GET_PRODUCTION_DATA,
  UPDATE_PRODUCTION,
  CREATE_PRODUCTION,
  GET_COMPANIES,
  GET_ORGANIZATIONS,
  CLEAN_OPTIONS_VALUES,
  SET_PRODUCTION,
  GET_PRODUCTION_NAMES,
  CREATE_PRODUCTION_CASTS,
  SET_PROFESSIONS,
  SET_ROLES,
  UPDATE_PRODUCTION_PERFORMANCES,
  GET_VENUES,
  CREATE_COMPOSER,
  GET_PRODUCTION_CASTS,
  GET_PRODUCTION_DATES,
  GET_ARTIST_PRODUCTION_DATES,
  GET_ARTISTS_SUGGESTIONS,
  UPDATE_MEDIA,
  CLIENT_REMOVE_MEDIA,
  GET_PRODUCTION_MEDIA_ATTRIBUTE_TYPES,
  GET_FESTIVALS,
  GET_PERFORMANCE_CITIES,
  CLEAR_PERFORMANCE_CITIES,
  GET_PERFORMANCE_STAGES,
  CLEAR_PERFORMANCE_STAGES,
  ADD_SEVERAL_MEDIA,
  CLEAR_PRODUCTION,
  GET_WORKS,
  GET_PRODUCTION_SYNOPSIS,
  DELETE_PRODUCTION_DOCUMENT,
  UPLOAD_PRODUCTION_DOCUMENTS,
  UPDATE_PRODUCTION_SYNOPSIS_DOCUMENTS,
  GET_PRODUCTION_IMAGES,
  GET_PRODUCTION_VIDEO,
  ADD_PRODUCTION_IMAGE,
  ADD_PRODUCTION_VIDEO,
  ADD_PRODUCTION_POSTER,
  ADD_PRODUCTION_TRAILER,
  UPDATE_PRODUCTION_LIVESTREAM,
  GET_PRODUCTION_LIVESTREAMS,
  GET_COMPOSERS,
  GET_PRODUCTION_VIDEO_ON_DEMAND,
  UPDATE_VIDEO_ON_DEMAND,
  GET_VIDEO_COST_TYPES,
  GET_IMAGE_FORMATS,
  GET_VIDEO_FORMATS,
  CREATE_DIGITAL_EVENT,
  DELETE_DIGITAL_EVENT,
  GET_LIVE_EVENTS_STEP_DATA,
  GET_DIGITAL_EVENTS_STEP_DATA,
  GET_MEDIA_STEP_DATA,
  GET_MW_STEP_DATA,
  CREATE_PRODUCTION_SYNOPSIS_AND_DESCRIPTION,
  GET_PRODUCTION_SYNOPSIS_AND_DESCRIPTION,
  GET_PRODUCTION_TRAILER,
  DELETE_PRODUCTION_TRAILER,
  DELETE_PRODUCTION_POSTER,
  GET_PRODUCTION_POSTER,
  GET_ENSEMBLES,
  CREATE_PRODUCTION_ENSEMBLES,
  GET_PRODUCTION_ENSEMBLES,
  GET_CAST_CREW_DATA,
  GET_REVIEWS,
  DELETE_REVIEWS,
  GET_REVIEW_DETAILS,
  GET_PRODUCTION_DESCRIPTION,
  CREATE_PRODUCTION_DESCRIPTION,
  UPDATE_PRODUCTION_DESCRIPTION,
  DELETE_PRODUCTION_DESCRIPTION,
  GET_NEW_PRODUCTION_SYNOPSIS,
  UPDATE_PRODUCTION_SYNOPSIS,
  DELETE_PRODUCTION_SYNOPSIS,
  GET_PRODUCTION_BOOKLET_DATA,
  CREATE_PRODUCTION_BOOKLET,
  UPDATE_PRODUCTION_BOOKLET,
  DELETE_CAST,
  GET_WORK_CAST_CREW,
  GET_PRODUCTION_WORKS,
  SET_PRODUCTION_WORKS,
  CREATE_PERFORMANCES,
  CREATE_PRODUCTION_DATES_V1,
  GET_PRODUCTION_DATES_V1,
  GET_PRODUCTION_CALENDAR_DATES,
  DELETE_PRODUCTION_BOOKLET,
  PRODUCTION_MEDIA_ACTIONS,
  UPDATE_PRODUCTION_DATES_V1,
  DELETE_CAST_PROXY,
  GET_PERFORMANCE_VIDEO,
  GET_PERFORMANCE_IMAGES,
  DELETE_MEDIA_ACTION,
  DELETE_PRODUCTION_ENSEMBLE,
  CREATE_PRODUCTION_DATES_PROXY,
  GET_WORK_STAGE_TYPE,
  CLEAR_WORK_STAGING_TYPES,
  CLEAR_COMPOSERS,
  CLEAR_WORKS,
} from './constants';

export const getProductionDataAction = createRoutine(GET_PRODUCTION_DATA);
export const updateProductionAction = createRoutine(UPDATE_PRODUCTION);
export const createProductionAction = createRoutine(CREATE_PRODUCTION);
export const getCompaniesAction = createRoutine(GET_COMPANIES);
export const getOrganizationsAction = createRoutine(GET_ORGANIZATIONS);
export const getArtistNamesAction = createRoutine(GET_PRODUCTION_NAMES);
export const getVenuesAction = createRoutine(GET_VENUES);
export const createProductionCastAction = createRoutine(CREATE_PRODUCTION_CASTS);
export const getProductionCastAction = createRoutine(GET_PRODUCTION_CASTS);
export const updateProductionPerformancesAction = createRoutine(UPDATE_PRODUCTION_PERFORMANCES);
export const createComposerAction = createRoutine(CREATE_COMPOSER);
export const setProfessions = createRoutine(SET_PROFESSIONS);
export const setRoles = createRoutine(SET_ROLES);
export const getProductionDatesAction = createRoutine(GET_PRODUCTION_DATES);
export const getArtistsSuggestionsAction = createRoutine(GET_ARTISTS_SUGGESTIONS);
export const getProductionImagesAction = createRoutine(GET_PRODUCTION_IMAGES);
export const getProductionVideoAction = createRoutine(GET_PRODUCTION_VIDEO);
export const getProductionTrailerAction = createRoutine(GET_PRODUCTION_TRAILER);
export const deleteProductionTrailerAction = createRoutine(DELETE_PRODUCTION_TRAILER);
export const deleteProductionPosterAction = createRoutine(DELETE_PRODUCTION_POSTER);
export const getProductionPosterAction = createRoutine(GET_PRODUCTION_POSTER);
export const getMediaAttributes = createRoutine(GET_PRODUCTION_MEDIA_ATTRIBUTE_TYPES);
export const getFestivalsAction = createRoutine(GET_FESTIVALS);
export const getPerformanceCitiesAction = createRoutine(GET_PERFORMANCE_CITIES);
export const clearPerformanceCitiesAction = createRoutine(CLEAR_PERFORMANCE_CITIES);
export const getPerformanceStagesAction = createRoutine(GET_PERFORMANCE_STAGES);
export const clearPerformanceStagesAction = createRoutine(CLEAR_PERFORMANCE_STAGES);
export const uploadSeveralMediaAction = createRoutine(ADD_SEVERAL_MEDIA);
export const getWorksAction = createRoutine(GET_WORKS);
export const clearWorksAction = createRoutine(CLEAR_WORKS);
export const getProductionSinopsisAction = createRoutine(GET_PRODUCTION_SYNOPSIS);
export const getProductionImageFormatsAction = createRoutine(GET_IMAGE_FORMATS);
export const getProductionVideoFormats = createRoutine(GET_VIDEO_FORMATS);
export const updateProductionSynopsisDocumentsAction = createRoutine(UPDATE_PRODUCTION_SYNOPSIS_DOCUMENTS);
export const addProductionImageAction = createRoutine(ADD_PRODUCTION_IMAGE);
export const addProductionVideoAction = createRoutine(ADD_PRODUCTION_VIDEO);
export const addProductionPosterAction = createRoutine(ADD_PRODUCTION_POSTER);
export const addProductionTrailerAction = createRoutine(ADD_PRODUCTION_TRAILER);
export const getProductionLivestreamsAction = createRoutine(GET_PRODUCTION_LIVESTREAMS);
export const getProductionVideoOnDemandAction = createRoutine(GET_PRODUCTION_VIDEO_ON_DEMAND);
export const getComposersAction = createRoutine(GET_COMPOSERS);
export const clearComposersAction = createRoutine(CLEAR_COMPOSERS);
export const getWorkStageTypeAction = createRoutine(GET_WORK_STAGE_TYPE);
export const clearWorkStagingTypesAction = createRoutine(CLEAR_WORK_STAGING_TYPES);
export const updateVideoOnDemandAction = createRoutine(UPDATE_VIDEO_ON_DEMAND);
export const updateLivestreamAction = createRoutine(UPDATE_PRODUCTION_LIVESTREAM);
export const getVideoCostTypesAction = createRoutine(GET_VIDEO_COST_TYPES);
export const createDigitalEventAction = createRoutine(CREATE_DIGITAL_EVENT);
export const deleteDigitalEventAction = createRoutine(DELETE_DIGITAL_EVENT);
export const getPerformanceImagesAction = createRoutine(GET_PERFORMANCE_IMAGES);
export const getPerformanceVideoAction = createRoutine(GET_PERFORMANCE_VIDEO);
export const deleteMediaAction = createRoutine(DELETE_MEDIA_ACTION);
export const deleteProductionEnsembleAction = createRoutine(DELETE_PRODUCTION_ENSEMBLE);

// artist specific data
export const getArtistProductionDatesAction = createRoutine(GET_ARTIST_PRODUCTION_DATES);
export const uploadProductionDocumentsAction = createRoutine(UPLOAD_PRODUCTION_DOCUMENTS);

// New production screen
export const getReviewsAction = createRoutine(GET_REVIEWS);
export const deleteReviewAction = createRoutine(DELETE_REVIEWS);
export const getReviewDetailsAction = createRoutine(GET_REVIEW_DETAILS);
export const getProductionDatesV1Action = createRoutine(GET_PRODUCTION_DATES_V1);
export const getProductionCalendarDatesAction = createRoutine(GET_PRODUCTION_CALENDAR_DATES);

// STEPS ACTIONS
export const getLiveEventsStepDataAction = createRoutine(GET_LIVE_EVENTS_STEP_DATA);
export const getDigitalEventsStepDataAction = createRoutine(GET_DIGITAL_EVENTS_STEP_DATA);
export const getMediaStepDataAction = createRoutine(GET_MEDIA_STEP_DATA);
export const createProductionSynopsisAndDescription = createRoutine(CREATE_PRODUCTION_SYNOPSIS_AND_DESCRIPTION);
export const getProductionSynopsisAndDescription = createRoutine(GET_PRODUCTION_SYNOPSIS_AND_DESCRIPTION);
export const getProductionSynopsisAction = createRoutine(GET_NEW_PRODUCTION_SYNOPSIS);
export const updateProductionSynopsisAction = createRoutine(UPDATE_PRODUCTION_SYNOPSIS);
export const deleteProductionSynopsisAction = createRoutine(DELETE_PRODUCTION_SYNOPSIS);
export const getProductionDescriptionsAction = createRoutine(GET_PRODUCTION_DESCRIPTION);
export const createNewProductionDescription = createRoutine(CREATE_PRODUCTION_DESCRIPTION);
export const updateProductionDescriptionAction = createRoutine(UPDATE_PRODUCTION_DESCRIPTION);
export const deleteProductionDescriptionAction = createRoutine(DELETE_PRODUCTION_DESCRIPTION);
export const getEnsembles = createRoutine(GET_ENSEMBLES);
export const createProductionEnsembles = createRoutine(CREATE_PRODUCTION_ENSEMBLES);
export const getCastAndCrewAction = createRoutine(GET_CAST_CREW_DATA);
export const getProductionEnsembles = createRoutine(GET_PRODUCTION_ENSEMBLES);
export const getProductionBookletsAction = createRoutine(GET_PRODUCTION_BOOKLET_DATA);
export const addNewProductionBookletAction = createRoutine(CREATE_PRODUCTION_BOOKLET);
export const updateProductionBookletAction = createRoutine(UPDATE_PRODUCTION_BOOKLET);
export const productionMediaActions = createRoutine(PRODUCTION_MEDIA_ACTIONS);
export const deleteCastAction = createRoutine(DELETE_CAST);
export const getWorkCastCrewAction = createRoutine(GET_WORK_CAST_CREW);
export const getProductionWorksAction = createRoutine(GET_PRODUCTION_WORKS);
export const createPerformancesAction = createRoutine(CREATE_PERFORMANCES);
export const deleteCastProxyAction = createRoutine(DELETE_CAST_PROXY);

export function setProductionWorksAction(works) {
  return {
    type: SET_PRODUCTION_WORKS,
    payload: { works },
  };
}
export const createProductionDatesV1Action = createRoutine(CREATE_PRODUCTION_DATES_V1);
export const updateProductionDatesV1Action = createRoutine(UPDATE_PRODUCTION_DATES_V1);
export const deleteProductionBookletAction = createRoutine(DELETE_PRODUCTION_BOOKLET);

export const createProductionDatesProxyAction = createRoutine(CREATE_PRODUCTION_DATES_PROXY);

export function setProduction(production) {
  return {
    type: SET_PRODUCTION,
    payload: { production },
  };
}
export function clearProductionAction() {
  return {
    type: CLEAR_PRODUCTION,
    payload: {},
  };
}

export function cleanOptionsValuesAction(field) {
  return {
    type: CLEAN_OPTIONS_VALUES,
    payload: { field },
  };
}

export function deleteProductionDocumentAction(documentId) {
  return {
    type: DELETE_PRODUCTION_DOCUMENT,
    payload: { id: documentId },
  };
}

export function clientRemoveMediaAction(mediaId, type) {
  return {
    type: CLIENT_REMOVE_MEDIA,
    payload: { id: mediaId, type },
  };
}

export function updateMediaAction(media, type) {
  return {
    type: UPDATE_MEDIA,
    payload: { entity: media, type },
  };
}
