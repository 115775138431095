import React, { createContext, useState, useEffect } from 'react';
import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import usePageContext from 'utils/hooks/usePageContext';

// eslint-disable-next-line import/no-cycle
import QuickView from './QuickView';

const QuickViewContext = createContext({});

export const QuickViewProvider = ({ children }) => {
  const [quickViewProps, setQuickViewProps] = useState(null);
  const isOpen = !!quickViewProps?.type;
  const { pathname } = usePageContext();

  const hideQuickView = () => {
    if (quickViewProps?.onClose) {
      quickViewProps.onClose();
    }
    setQuickViewProps(null);
  };

  useScrollBlocker(isOpen);

  useEffect(() => {
    if (isOpen) {
      hideQuickView();
    }
  }, [pathname]); // Close quick view when pathname changes

  return (
    <QuickViewContext.Provider
      value={{ quickViewType: quickViewProps?.type, showQuickView: setQuickViewProps, hideQuickView }}
    >
      <>
        {children}
        <QuickView hideQuickView={hideQuickView} quickViewProps={quickViewProps} />
      </>
    </QuickViewContext.Provider>
  );
};

export default QuickViewContext;
