import { stringify } from 'query-string';
import { get, cloneDeep } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import cookie from 'cookie';
import { getHmacDigestValue } from './serverSignature';
import * as fetchUtils from './fetch';
import { i18n } from '../src/i18n';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  GET,
} from '../containers/App/constants';
import config from '../config/config.json';

const getRequestLanguage = serverData => {
  if (typeof window === 'undefined') {
    return get(serverData, 'language', 'en');
  }
  return i18n.language;
};

const httpClient = (uri, requestOptions = { headers: [] }) => {
  const options = cloneDeep(requestOptions);
  let url;
  const path = `${uri}`;
  if (typeof window === 'undefined' && !path.startsWith('auth/')) {
    url = `http://127.0.0.1:3000/${path}`;
    return global.apiTunnel(url, options);
  }
  url = `${config.FRONTBASE_URL}/${path}`;
  return fetchUtils.fetchJson(url, options);
};

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataRequestToHTTP = (type, resource, params, abortSignal = null, requestLang) => {
  let url = '';
  const options = {};
  let language = requestLang || 'en';
  if (language === 'keys') {
    language = 'en';
  }
  const { lang = language, cancelToken } = params || {};
  if (cancelToken) {
    options.cancelToken = cancelToken;
  }
  switch (type) {
    case GET_LIST: {
      const { page = 1, limit = 10 } = get(params, 'pagination', {});
      const queryParams = get(params, 'queryParams', {});
      const query = {
        ...fetchUtils.flattenObject(get(params, 'filter')),
        ...fetchUtils.flattenObject({ sort: get(params, 'sort') }),
        page,
        limit,
        query: params.query,
        lang,
        ...fetchUtils.flattenObject(queryParams),
      };
      url = `${resource}?${stringify(query, { skipNull: true })}`;
      options.method = 'GET';
      options.signal = abortSignal;
      break;
    }
    case GET_ONE: {
      const query = { lang, ...get(params, 'queryParams', {}) };
      url = `${resource}/${params.id}?${stringify(query, { skipNull: true })}`;
      options.method = 'GET';
      break;
    }
    case GET: {
      const query = { lang, ...get(params, 'queryParams', {}) };
      url = `${resource}?${stringify(query, { skipNull: true })}`;
      options.method = 'GET';
      break;
    }
    case GET_MANY_REFERENCE: {
      const { page, limit = 10 } = params.pagination;
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        [params.target]: params.id,
        ...fetchUtils.flattenObject({ sort: params.sort }),
        page,
        limit,
        query: params.query,
        lang,
      };
      url = `${resource}?${stringify(query, { skipNull: true })}`;
      options.method = 'GET';
      break;
    }
    case UPDATE: {
      const queryParams = get(params, 'queryParams', {});
      const query = {
        lang,
        ...fetchUtils.flattenObject(queryParams),
      };
      const id = params.id ? `/${params.id}` : ''; // for api folder structure with put method without inner id
      url = `${resource}${id}?${stringify(query, { skipNull: true })}`;
      options.method = 'PUT';
      options.body = JSON.stringify(params.data);
      options.files = params.files;
      break;
    }
    case CREATE: {
      const queryParams = get(params, 'queryParams', {});
      const query = {
        lang,
        ...fetchUtils.flattenObject(queryParams),
      };
      url = `${resource}?${stringify(query, { skipNull: true })}`;
      options.method = 'POST';
      options.body = JSON.stringify(params.data);
      options.files = params.files;
      break;
    }
    case DELETE: {
      const queryParams = get(params, 'queryParams', {});
      const query = {
        lang,
        ...fetchUtils.flattenObject(queryParams),
      };
      const id = params.id ? `/${params.id}` : ''; // for api folder structure with put method without inner id
      url = `${resource}${id}?${stringify(query, { skipNull: true })}`;
      options.method = 'DELETE';
      break;
    }
    case GET_MANY: {
      const query = {
        [`id`]: params.ids.join(','),
        [`limit`]: params.ids.length,
      };
      url = `${resource}?${stringify(query, { skipNull: true })}`;
      options.method = 'GET';
      break;
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
  return { url, options };
};
/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} Data response
 */
const convertHTTPResponse = (response, type, resource, params) => {
  const json = response.body;
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      if (!params?.skipTotalCheck && typeof !json?.total === 'undefined') {
        throw new Error(
          'The "total" is missing in the HTTP Response. The Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination.',
        );
      }
      return {
        data: json,
        total: parseInt(json?.total || 0, 10),
        limit: parseInt(json?.limit || 0, 10),
        page: parseInt(json?.page || 0, 10),
      };
    case CREATE:
      return { data: json };
    default:
      return { data: json };
  }
};
/**
 * @param {String} http request url
 * @param {options} request options
 * @returns {String} hmac signature with timestamp
 */
const generateSignature = (url, options) => {
  const clearUrl = url.replace('api/', '');
  const publicKey = process.env.PUBLIC_KEY;
  const method = isEmpty(options) ? 'GET' : options.method;
  const body = options && options.body ? options.body : '';
  // get the current epoch timestamp
  const timeStamp = Math.round(new Date().getTime() / 1000);
  // concatinate url, method, body and timestamp
  const finalString = clearUrl + method + body + timeStamp;
  // generate frontbase ui signature
  const hmacDigest = getHmacDigestValue(finalString, publicKey);
  // generate final signature by combinging signatur and timestamp
  const finalSignature = `${hmacDigest}:${timeStamp}`;
  return finalSignature;
};

/**
 * Maps react queries to a json-server powered REST API
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=title
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default function dataProvider() {
  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params, serverCookies, abortSignal) => {
    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${resource}/${id}`, {
            method: 'PUT',
            data: JSON.stringify(params.data),
          }),
        ),
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }
    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${resource}/${id}`, {
            method: 'DELETE',
          }),
        ),
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }
    const requestLanguage = getRequestLanguage(serverCookies);
    let pagePath = serverCookies?.pathname;

    if (!pagePath && typeof window !== 'undefined') {
      pagePath = window?.location?.pathname;
    }

    const contextHeader = {
      'x-build-number': process.env.BUILD_NUMBER,
    };

    // to abort API call and return if feature is disabled
    const disableFeatures = get(config, 'DISABLE_FEATURES', []);
    if (params?.disableFlag) {
      if (disableFeatures.includes(params.disableFlag)) {
        return null;
      }
      // eslint-disable-next-line no-param-reassign
      delete params.disableFlag;
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params, abortSignal, requestLanguage);

    // add signature to CSR request headers and Cookies to SSR request headers
    if (typeof window === 'undefined') {
      options.headers = { ...options.headers, ...contextHeader };

      if (serverCookies && serverCookies.cookies) {
        const cookies =
          typeof serverCookies.cookies === 'string' ? cookie.parse(serverCookies.cookies) : serverCookies.cookies;
        options.cookies = { ...options.cookies, ...cookies };

        const cookieOptions = {
          cookie: serverCookies.cookies,
        };
        options.headers = { ...options.headers, ...cookieOptions, ...contextHeader };
      }
    } else {
      const signature = generateSignature(url, options);

      // add signature to request headers
      const FrontbaseAuth = {
        'x-frontbase-auth': signature,
      };
      options.headers = { ...options.headers, ...FrontbaseAuth, ...contextHeader };
    }

    return httpClient(url, options)
      .then(response => {
        if (typeof window !== 'undefined' && window.notifyAppVersionUpdate) {
          const isDeprecatedVersion = (response.headers['x-deprecated-version'] ?? false) === 'true';

          if (isDeprecatedVersion) {
            const latestVersion = response.headers['x-build-number'];

            window.notifyAppVersionUpdate({
              latestVersion,
              isDeprecated: isDeprecatedVersion,
            });
          }
        }

        return response;
      })
      .then(response => convertHTTPResponse(response, type, resource, params));
  };
}
