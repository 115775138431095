import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { getIdPrefixSlugType } from 'utils/globals';
import { ANALYTICS_SERVICES } from './constants';
import { removeEmptyValues } from './utils';

const transformEventForArts = event => {
  const { filters, advancedFilters, searchQuery, searchResultPosition, entityId, meta, ...rest } = event;
  const parsedEntityId = getIdPrefixSlugType(entityId).id;
  return {
    ...rest,
    entityId: parsedEntityId,
    meta: removeEmptyValues({
      ...(!_isEmpty(filters) && { filters }),
      ...(!_isEmpty(advancedFilters) && { advancedFilters }),
      searchQuery,
      searchResultPosition,
      ...meta,
    }),
  };
};

const transformEventForGoogleAnalytics = event => {
  const { filters, advancedFilters, searchQuery, meta, eventType, eventName, data, ...rest } = event;
  const transformedEvent = {
    ...meta, // TODO: Think about whitelisting meta keys _pick(meta, [WHITELISTED_KEYS])
    ...rest,
    ...data,
    name: eventType, // click / pageView
    id: eventName, // Human readable name
    query: searchQuery, // for GA, we send query as search term
  };

  // Send meta.id as metaId, to be consumed by Google Analytics (Tag Manager)
  if (meta?.id) {
    transformedEvent.metaId = meta.id;
  }

  if (meta?.section) {
    transformedEvent.metaSection = meta.section;
  }

  const filterKeys = Object.keys(filters || {});

  transformedEvent.filters = filterKeys.join(',');
  transformedEvent.advancedFilters = advancedFilters?.join(',');

  return transformedEvent;
};

// Transformers for each analytics service
const transformers = {
  [ANALYTICS_SERVICES.GOOGLE]: transformEventForGoogleAnalytics,
  [ANALYTICS_SERVICES.ARTS_CONSOLIDATED]: transformEventForArts,
};

export default transformers;
