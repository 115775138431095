import get from 'lodash/get';
import { sentryCaptureException } from 'utils/globals/sentry';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { selectUserData } from '../../App/selectors';
import {
  reactivateSubscriptionAction,
  changePasswordAction,
  getProductsAction,
  getUserPaymentsAction,
  updateSubscriptionAction,
  getAllProductsAction,
} from './actions';
import {
  changePassword,
  getUserPayments,
  reactivateUserSubscription,
  updateUserSubscription,
  getProductsList,
} from '../../../utils/API';
import { selectProducts } from './selectors';

function* changePasswordSaga({ payload, serverCookies }) {
  try {
    const userId = get(payload, 'user.id');
    const oldPassword = get(payload, 'password');
    const newPassword = get(payload, 'newPassword');
    const response = yield call(changePassword, userId, oldPassword, newPassword, serverCookies);
    yield put(changePasswordAction.success(response.data));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback({ status: 'ok', data: response.data });
    }
  } catch (err) {
    yield put(changePasswordAction.failure(err));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback({ status: 'error' });
    }
  } finally {
    yield put(changePasswordAction.fulfill());
  }
}

function* getProductsSaga({ serverCookies }) {
  try {
    const products = yield select(selectProducts());
    if (!products.loadedOnce) {
      const response = yield call(getProductsList, serverCookies);
      yield put(getProductsAction.success(get(response, 'data', [])));
    }
  } catch (e) {
    yield put(getProductsAction.failure(e));
  } finally {
    yield put(getProductsAction.fulfill());
  }
}

function* getAllProductsSaga({ serverCookies }) {
  try {
    const response = yield call(getProductsList, serverCookies, ['subscription']);
    yield put(getProductsAction.success(get(response, 'data', [])));
  } catch (e) {
    yield put(getProductsAction.failure(e));
  } finally {
    yield put(getProductsAction.fulfill());
  }
}

function* getUserPaymentsSaga({ payload, serverCookies }) {
  try {
    const userData = yield select(selectUserData());
    const userId = get(userData, 'id');
    if (!userId) {
      throw new Error('User undefined');
    }
    const response = yield call(getUserPayments, userId, payload?.query || {}, serverCookies);
    yield put(getUserPaymentsAction.success(get(response, 'data', [])));
  } catch (e) {
    yield put(getUserPaymentsAction.failure(e));
  } finally {
    yield put(getUserPaymentsAction.fulfill());
  }
}

function* reactivateSubscriptionSaga({ payload, serverCookies }) {
  try {
    if (!payload.userId) {
      const error = new Error('Reactivate Subscription failed: User undefined');
      sentryCaptureException(error, {
        extra: {
          payload,
        },
      });
      throw error;
    }
    if (!payload.subId) {
      const error = new Error('Reactivate Subscription failed: Subscription id is undefined');
      sentryCaptureException(error, {
        extra: {
          payload,
        },
      });
      throw error;
    }
    yield call(
      reactivateUserSubscription,
      payload.userId,
      payload.subId,
      payload.isTrialReactivation ? 'in_trial' : 'active',
      serverCookies,
    );
    if (payload.callback) {
      payload.callback({ status: 'ok' });
    }

    yield put(reactivateSubscriptionAction.success());
  } catch (e) {
    if (payload.callback) {
      e.message = `Reactivate Subscription failed: ${e.message}`;
      sentryCaptureException(e, {
        extra: {
          payload,
        },
      });
      payload.callback({ status: 'fail', error: e });
    }
    yield put(reactivateSubscriptionAction.failure(e));
  } finally {
    yield put(reactivateSubscriptionAction.fulfill());
  }
}

function* updateSubscriptionSaga({ payload, serverCookies }) {
  try {
    if (!payload.userId) {
      const error = new Error('Upgrade Subscription failed: User undefined');
      sentryCaptureException(error, {
        extra: {
          payload,
        },
      });
      throw error;
    }
    if (!payload.subId) {
      const error = new Error('Upgrade Subscription failed: Subscription id is undefined');
      sentryCaptureException(error, {
        extra: {
          payload,
        },
      });
      throw error;
    }
    yield call(updateUserSubscription, payload.userId, payload.subId, payload.data, serverCookies);
    if (payload.callback) {
      payload.callback({ status: 'ok' });
    }

    yield put(reactivateSubscriptionAction.success());
  } catch (e) {
    e.message = `Upgrade subscription failed: ${e.message}`;
    sentryCaptureException(e, {
      extra: {
        payload,
      },
    });
    if (payload.callback) {
      payload.callback({ status: 'fail', error: e });
    }
    yield put(reactivateSubscriptionAction.failure(e));
  } finally {
    yield put(reactivateSubscriptionAction.fulfill());
  }
}

export default function* accountSettingsSaga() {
  yield takeLatest(reactivateSubscriptionAction.TRIGGER, reactivateSubscriptionSaga);
  yield takeLatest(changePasswordAction.TRIGGER, changePasswordSaga);
  yield takeLatest(getProductsAction.TRIGGER, getProductsSaga);
  yield takeLatest(getAllProductsAction.TRIGGER, getAllProductsSaga);
  yield takeLatest(getUserPaymentsAction.TRIGGER, getUserPaymentsSaga);
  yield takeLatest(updateSubscriptionAction.TRIGGER, updateSubscriptionSaga);
}
