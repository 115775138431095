import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import { deleteOne, getList, getWholeList } from 'utils/API';
import { getLanguage } from 'utils/queriesUtil';
import { createDate } from 'utils/date';
import { getEntityIdWithPrefix, transformEntity } from 'utils/globals';
import { getEntityPerformedDates, getMinMaxProductionDates } from 'utils/productions';
import { transformFiltersToParams } from 'utils/globals/filters';
import { getQuery, postQuery, withSelectFn } from 'utils/globals/queries';

import { formatResponseToList } from 'utils/crudUtils';

import {
  PRODUCTIONS,
  ORGANIZATIONS,
  AGENCIES,
  MEDIA,
  PERFORMANCES,
  CASTS_ENDPOINT,
  PRODUCTION_CASTS,
  PRODUCTIONS_REVIVALS,
} from 'constants/endpointConstants';
import { FILTER_AGGREGATION_MAP } from 'constants/filters';
import {
  PRODUCTION_DOCUMENT_TYPES,
  MEDIA_TAGS_TYPES_IDS,
  MEDIA_TYPES_IDS,
  MEDIA_ATTRIBUTES_TYPES_IDS,
} from 'constants/consts';
import {
  ENTITY_TYPE,
  VIEW_MODES_VALUE,
  AGGREGATION_TYPES,
  URL_SLUG_TYPES,
  PERIOD_VALUE,
  SPOTLIGHT_PRODUCTION_TYPE,
  SORT_OPTION_TYPES,
  PRODUCTION_VALIDITY_IDENTIFIERS,
  PROFESSION_IDS,
  AGGREGATION_ON,
} from 'constants/index';
import { useMutation } from 'utils/react-query';

import { selectAggregatedOptions } from './transformers';

export const ENTITY_TYPE_KEY_MAP = {
  [ENTITY_TYPE.ARTIST]: 'individual_id',
  [ENTITY_TYPE.PRODUCTION]: 'id',
  [ENTITY_TYPE.WORK]: 'work_id',
  [ENTITY_TYPE.ORGANIZATION]: 'organization_id',
};

const getProductionListParams = ({ entityType, entityId, filters, limit, page }) => {
  const entityParams = {};

  const ctxEntityId = getEntityIdWithPrefix({ entityType, entityId });
  if ([ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.ARTIST].includes(entityType)) {
    if (entityType === ENTITY_TYPE.ORGANIZATION) {
      entityParams.approved_by = PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG;
    } else {
      entityParams.include_virtual_contributors = true;
    }
    entityParams.ctx_entity = ctxEntityId;
  } else if (ENTITY_TYPE_KEY_MAP[entityType] && entityId) {
    entityParams[ENTITY_TYPE_KEY_MAP[entityType]] = entityId;
  }

  const params = {
    queryParams: {
      ...entityParams,
      ...filters,
    },
    pagination: {
      limit,
      page,
    },
  };

  return params;
};

const fetchProductionMedia = async ({ productionIds, mediaLimit }, cookies) => {
  if (!productionIds?.length) {
    return null;
  }

  const mediaParams = {
    queryParams: {
      media_type_id: [MEDIA_TYPES_IDS.IMAGE, MEDIA_TYPES_IDS.VIDEO],
      media_tag_id: productionIds,
      media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
      exclude_media_attribute_id: MEDIA_ATTRIBUTES_TYPES_IDS.BANNER,
      sort: 'order,-performance',
      distinct: 'production_id',
      distinct_limit: mediaLimit,
    },
    pagination: {
      limit: productionIds?.length * mediaLimit,
      page: 1,
    },
  };

  const groupedProductionMedia = await getList(MEDIA, mediaParams, cookies).then(response => {
    const data = response?.data?.data || [];

    return groupBy(data, file => file?.production?.id);
  });

  return groupedProductionMedia;
};

const fetchProductions = async ({ entityType, entityId, filters, limit = 10, mediaLimit, page }, cookies) => {
  const params = getProductionListParams({ entityType, entityId, filters, limit, page });

  const productions = await getList(PRODUCTIONS, params, cookies);
  let productionMedia;

  if (mediaLimit > 0) {
    productionMedia = await fetchProductionMedia(
      {
        productionIds: productions?.data?.data?.map(production => production?.id),
        mediaLimit: 5,
      },
      cookies,
    );
  }

  return {
    ...productions?.data,
    data: productions?.data?.data?.map(production => ({
      ...transformEntity(production, ENTITY_TYPE.PRODUCTION),
      media: productionMedia?.[production?.id],
    })),
  };
};

const fetchPerformances = async ({ entityType, entityId, filters, limit = 10, mediaLimit, page }, cookies) => {
  const params = getProductionListParams({ entityType, entityId, filters, limit, page });

  const performances = await getList(PERFORMANCES, params, cookies);
  let productionMedia;

  if (mediaLimit > 0) {
    productionMedia = await fetchProductionMedia(
      {
        productionIds: performances?.data?.data?.map(performance => performance?.production?.id),
        mediaLimit: 5,
      },
      cookies,
    );
  }

  return {
    ...performances?.data,
    data: performances?.data?.data?.map(performance => ({
      ...transformEntity(performance, ENTITY_TYPE.PRODUCTION),
      media: productionMedia?.[performance?.production?.id],
    })),
  };
};

const fetchProduction = ({ id, program_published }, cookies) =>
  fetchProductions({ filters: { id, program_published } }, cookies).then(response => response?.data?.[0] || null);

const filterPrimaryCrew = (member, profileId) => {
  const isPrimaryCrew = [PROFESSION_IDS.STAGE_DIRECTOR, PROFESSION_IDS.CONDUCTOR].includes(member?.profession?.id);

  if (profileId) {
    return isPrimaryCrew || member?.profile?.id === profileId;
  }

  return isPrimaryCrew;
};

export const transformProductionData = ({
  production,
  profileId,
  onlyPrimaryCrew = false,
  onlyMinMaxDates = false,
  excludeKeys = [],
}) => ({
  ...omit(transformEntity(production, ENTITY_TYPE.PRODUCTION), ['privacy', ...excludeKeys]),
  ...(onlyMinMaxDates && {
    dates: [],
    performanceDatesCount: production?.performances?.length,
    ...getMinMaxProductionDates(production),
  }),
  ...(onlyPrimaryCrew && {
    casts: production?.casts?.filter(member => filterPrimaryCrew(member, profileId)),
    crew: production?.crew?.filter(member => filterPrimaryCrew(member, profileId)),
  }),
  artistPerformanceDatesCount: getEntityPerformedDates(production, profileId)?.artistPerformedDates || 0,
});

// TODO: Remove this function after refactoring Production queris
const fetchProductionsData = ({ entityType, entityId, limit = 5, page = 1, filters = {}, viewType }, cookies) => {
  const params = {
    pagination: {
      limit,
      page,
    },
    queryParams: {
      [ENTITY_TYPE_KEY_MAP[entityType]]: entityId,
      ...filters,
      sort: '-year',
    },
  };

  if (entityType === ENTITY_TYPE.ARTIST) {
    params.queryParams.ctx_entity = getEntityIdWithPrefix({ entityType, entityId });
    delete params.queryParams[ENTITY_TYPE_KEY_MAP[entityType]];
  }

  const API = viewType === VIEW_MODES_VALUE.PERFORMANCE ? PERFORMANCES : PRODUCTIONS;

  return getList(API, params, cookies).then(response => {
    const formattedResponse = formatResponseToList(response);

    return {
      ...formattedResponse,
      data: formattedResponse?.data?.map(production =>
        transformProductionData({
          production,
          onlyMinMaxDates: false,
          profileId: entityId,
        }),
      ),
    };
  });
};

const fetchProductions_DEPRECATED = (
  { entityType, entityId, limit = 5, page = 1, viewMode, filters = {}, asCount = false },
  cookies,
) => {
  let appliedFilters = {};

  if (entityType !== ENTITY_TYPE.PRODUCTION) {
    let sort = SORT_OPTION_TYPES?.YEAR_ASC.value;

    if (filters?.sort) {
      sort = filters?.sort;
    } else if (filters?.[URL_SLUG_TYPES.PRODUCTION_PERIOD] === PERIOD_VALUE.ALL) {
      sort = SORT_OPTION_TYPES?.YEAR_DESC.value;
    }

    appliedFilters = {
      ...filters,
      sort,
    };
  }

  if (filters?.production_id) {
    appliedFilters = {
      ...appliedFilters,
      ...(filters?.[URL_SLUG_TYPES.PRODUCTION_PERIOD] !== PERIOD_VALUE.ALL && {
        date_from: filters?.date_from,
      }),
    };
  }

  const params = {
    pagination: {
      limit: entityType === ENTITY_TYPE.PRODUCTION ? 1 : limit,
      page,
    },
    queryParams: {
      [ENTITY_TYPE_KEY_MAP[entityType]]: entityId,
      ...appliedFilters,
    },
  };

  const url = [VIEW_MODES_VALUE.DAY, VIEW_MODES_VALUE.CALENDAR, VIEW_MODES_VALUE.PERFORMANCE].includes(viewMode)
    ? PERFORMANCES
    : PRODUCTIONS;

  return getList(url, params, cookies).then(response => {
    if (entityType === ENTITY_TYPE.PRODUCTION) {
      return transformEntity(response?.data?.data?.[0], ENTITY_TYPE.PRODUCTION);
    }

    if (asCount) {
      return response?.data?.total;
    }

    const formattedResponse = formatResponseToList(response);

    return {
      ...formattedResponse,
      data: formattedResponse?.data?.map(production =>
        transformProductionData({
          production,
          onlyMinMaxDates: false,
        }),
      ),
    };
  });
};

const fetchProductionsWithMedia = async ({ entityType, entityId, page = 1, limit, filters = {} }, cookies) => {
  const params = {
    pagination: {
      limit,
      page,
    },
    queryParams: {
      ...(entityId && { [ENTITY_TYPE_KEY_MAP[entityType]]: entityId }),
      sort: '-year',
      ...filters,
    },
  };

  const productions = await getList(PRODUCTIONS, params, cookies).then(response => formatResponseToList(response));
  const productionMedia = await fetchProductionMedia(
    {
      productionIds: productions?.data?.map(production => production?.id),
      mediaLimit: 5,
    },
    cookies,
  );

  return {
    ...productions,
    data: productions?.data?.map(production => ({
      ...production,
      media: productionMedia?.[production?.id],
    })),
  };
};

const fetchProductionDescriptions = (id, cookies) => {
  const params = {
    queryParams: {
      production_id: id,
      document_type: PRODUCTION_DOCUMENT_TYPES.DESCRIPTION,
    },
  };

  return getWholeList(`${PRODUCTIONS}/${id}/documents`, params, cookies).then(response => response?.data);
};

const fetchProductionBooklets = (id, cookies) => {
  const params = {
    queryParams: {
      production_id: id,
      document_type: PRODUCTION_DOCUMENT_TYPES.BOOKLET,
    },
  };

  return getWholeList(`${PRODUCTIONS}/${id}/documents`, params, cookies).then(response => response?.data?.data);
};

const fetchProductionSyopsis = (id, cookies) => {
  const params = {
    queryParams: {
      production_id: id,
    },
  };

  return getWholeList(`${PRODUCTIONS}/${id}/synopsis`, params, cookies).then(response => response?.data?.data);
};

const fetchAboutMedia = ({ id, limit = 5 }, cookies) => {
  const params = {
    queryParams: {
      media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
      media_tag_id: id,
      media_type_id: MEDIA_TYPES_IDS.IMAGE,
    },
    pagination: {
      limit,
    },
  };

  return getList(MEDIA, params, cookies).then(response => formatResponseToList(response)?.data);
};

const getValidAggregationFilterValues = ({
  aggregationType,
  aggregationFilter,
  query,
  filters,
  entityId,
  entityType,
  limit,
}) => {
  const entityKey = [ENTITY_TYPE_KEY_MAP[entityType]];
  const FILTER_KEY_MAP = {
    company: AGGREGATION_TYPES.COMPANY,
    festival: AGGREGATION_TYPES.FESTIVAL,
    venue: AGGREGATION_TYPES.VENUE,
    work: AGGREGATION_TYPES.WORK,
    language: AGGREGATION_TYPES.LANGUAGE,
    surtitle: AGGREGATION_TYPES.SURTITLE,
    creator: AGGREGATION_TYPES.CREATOR,
  };

  let appliedFilters = {
    aggregation_type: aggregationType,
    [entityKey]: entityId,
    ...(filters?.date ? filters?.date : {}),
    ...(query ? { aggregation_query: query } : {}),
  };

  Object.keys(filters).forEach(key => {
    const filterKey = FILTER_KEY_MAP[key];
    if (filterKey) {
      let filterValues = filters[key]?.map(({ id }) => id) || [];

      if (aggregationFilter === entityKey) {
        filterValues = [...filterValues, entityId];
      }

      appliedFilters = {
        ...appliedFilters,
        [filterKey]: filterValues,
      };
    }
  });

  return {
    queryParams: appliedFilters,
    pagination: {
      limit,
    },
  };
};

const fetchProductionAggregations = async (
  { entityType, entityId, query, aggregationType, queryFilters: filters, limit },
  cookies,
) => {
  const params = getValidAggregationFilterValues({
    aggregationType,
    aggregationFilter: AGGREGATION_TYPES.COMPANY,
    filters,
    entityType,
    entityId,
    query,
    limit,
  });

  return getList(`${PRODUCTIONS}/aggregations/`, params, cookies).then(response => response?.data);
};

const fetchSpotlightProductions = ({ limit, page = 1, filters = {} }) => {
  const params = {
    queryParams: {
      sort: 'year',
      ...filters,
    },
    pagination: {
      limit,
      page,
    },
  };

  return getList(PRODUCTIONS, params).then(response => response?.data);
};

export const fetchProductionsList = ({ productionsIds, limit, entityId, entityType }) => {
  const params = {
    queryParams: {
      id: productionsIds,
      ...(entityId && entityType && { [ENTITY_TYPE_KEY_MAP[entityType]]: entityId }),
    },
    pagination: {
      limit,
    },
  };

  return getList(PRODUCTIONS, params).then(response => response?.data);
};

const fetchPerformances_DEPRECATED = ({ entityType, entityId, filters, limit, page }, cookies) => {
  const params = {
    pagination: {
      limit,
      page,
    },
    queryParams: {
      ...(entityId && entityType && { [ENTITY_TYPE_KEY_MAP[entityType]]: entityId }),
      ...omit(filters, URL_SLUG_TYPES.PRODUCTION_PERIOD),
    },
  };

  return getList(PERFORMANCES, params, cookies).then(response => {
    const formattedResponse = formatResponseToList(response);

    return {
      ...formattedResponse,
      data: formattedResponse?.data?.map(production =>
        transformProductionData({
          production,
          profileId: entityType === ENTITY_TYPE.ARTIST && entityId,
          onlyPrimaryCrew: true,
          onlyMinMaxDates: true,
        }),
      ),
    };
  });
};

const fetchProductionsCasting = ({ filters, limit = 75, page }) => {
  const params = {
    queryParams: {
      ...filters,
    },
    pagination: {
      limit,
      page,
    },
  };

  return getList(CASTS_ENDPOINT, params).then(response => response?.data);
};

const fetchAggregations = ({ aggregationOn, aggregationType, filters = {}, limit = 300, page = 1 }, cookies) => {
  const params = {
    queryParams: {
      aggregation_type: aggregationType,
      sort: SORT_OPTION_TYPES.NAME_ASC.value,
      ...omit(filters, [aggregationType]),
    },
    pagination: {
      limit,
      page,
    },
  };

  let endpoint = `${PRODUCTIONS}/aggregations`;

  if (aggregationOn === AGGREGATION_ON.ORGANIZATIONS) {
    endpoint = `${ORGANIZATIONS}/aggregations`;
  } else if (aggregationOn === AGGREGATION_ON.MANAGERS) {
    endpoint = `${AGENCIES}/aggregations`;
  }

  return getList(endpoint, params, cookies).then(response => response?.data);
};

const fetchFilterStats = ({ types, filters }, cookies) => {
  const facetMap = types?.reduce((acc, type) => {
    const slug = FILTER_AGGREGATION_MAP[type];

    if (slug) {
      acc[slug] = type;
    }

    return acc;
  }, {});
  const params = {
    queryParams: {
      facets: Object.keys(facetMap),
      ...filters,
    },
    skipTotalCheck: true,
  };

  return getWholeList(`${PRODUCTIONS}/aggregations`, params, cookies).then(response => {
    const stats = response?.data?.meta || {};

    return Object.keys(stats)?.reduce((acc, value) => {
      const type = facetMap[value];

      if (type) {
        acc[type] = stats[value];
      }

      return acc;
    }, {});
  });
};

export function useDeleteProductionContributionMutation(options = {}) {
  return useMutation(({ productionId, castId }) => deleteOne(PRODUCTION_CASTS(productionId), castId), options);
}

const fetchSeasons = ({ filters }, cookies) =>
  fetchAggregations({ filters, aggregationType: AGGREGATION_TYPES.SEASON }, cookies);

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
  getNextPageParam: lastPage => {
    const { limit, page, total } = lastPage;
    const totalFetched = limit * page;

    return totalFetched < total ? page + 1 : undefined;
  },
  getPreviousPageParam: () => false,
};

const queries = {
  getProductions: getQuery('GET_PRODUCTIONS', fetchProductions),
  getPerformances: getQuery('GET_PERFORMANCES', fetchPerformances),
  getCountries: getQuery('GET_COUNTRY_AGGREGATIONS', props =>
    fetchAggregations({ ...props, aggregationType: AGGREGATION_TYPES.COUNTRY }),
  ),
  getCities: getQuery('GET_CITY_AGGREGATIONS', props =>
    fetchAggregations({ ...props, aggregationType: AGGREGATION_TYPES.CITY }),
  ),
  getSeasons: getQuery('GET_SEASONS', fetchSeasons, withSelectFn(selectAggregatedOptions)),
  getFilterCounts: getQuery('GET_FILTER_COUNTS', fetchFilterStats),
  getAggregatedEntities: getQuery('GET_AGGREGATED_ENTITIES', fetchAggregations),
  getFilterOptions: getQuery('GET_FILTER_OPTIONS', fetchAggregations),
  getProductionDetails: getQuery('GET_PRODUCTION_DETAILS', fetchProduction),
  getEntityProductionsData: getQuery('GET_ENTITY_PRODUCTIONS_DATA', fetchProductionsData, props => ({
    ...props,
    filters: transformFiltersToParams(props?.filters),
  })),
  getEntityProductions: (
    { entityType, entityId, limit = 5, viewMode = VIEW_MODES_VALUE.SUMMARY, filters = {}, queryConfig = {}, asCount },
    cookies,
    language,
  ) => {
    const transformedFilters = transformFiltersToParams(filters);
    return {
      queryKey: [
        getLanguage(language),
        'GET_ENTITY_PRODUCTIONS',
        `${entityType}`,
        `${entityId}`,
        `${limit}`,
        `${viewMode}`,
        JSON.stringify(transformedFilters),
      ],
      queryFn: async ({ pageParam = 1 }) =>
        fetchProductions_DEPRECATED(
          { entityType, entityId, limit, page: pageParam, viewMode, filters: transformedFilters, asCount },
          cookies,
        ),
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getPerformances_DEPRECATED: ({ entityType, entityId, limit, filters, queryConfig }, cookies, language) => {
    const transformedFilters = transformFiltersToParams(filters);

    return {
      queryKey: [
        getLanguage(language),
        'GET_PERFORMANCES',
        `${entityType}`,
        `${entityId}`,
        `${limit}`,
        JSON.stringify(transformedFilters),
      ],
      queryFn: async ({ pageParam = 1 }) =>
        fetchPerformances_DEPRECATED(
          { entityType, entityId, limit, page: pageParam, filters: transformedFilters },
          cookies,
        ),
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getRentals: getQuery('GET_ENTITY_RENTALS', fetchProductionsWithMedia, props => ({
    limit: 18,
    ...props,
    filters: {
      ...(props.entityId && props.entityType == ENTITY_TYPE.ORGANIZATION && { producer_id: `o${props.entityId}` }),
      ...props?.filters,
      has_active_rental: true,
      approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
    },
  })),
  getFeaturedProductions: ({ countryId = '', limit = 8, filters, type, queryConfig = {} }, cookies, language) => {
    const transformedFilters = {
      ...transformFiltersToParams(filters),
      date_from: createDate().format('YYYY-MM-DD'),
      sort: 'year',
      added_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
      approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
      ...(countryId && { country_id: countryId }),
      is_featured: true,
      ...(type === SPOTLIGHT_PRODUCTION_TYPE.HIGHLIGHTS && { is_highlight: true }),
      ...(type === SPOTLIGHT_PRODUCTION_TYPE.NEW_RARE && { is_new_and_rare: true }),
    };

    return {
      queryKey: [
        getLanguage(language),
        'GET_FEATURE_PRODUCTIONS',
        `${countryId}`,
        `${limit}`,
        JSON.stringify(transformedFilters),
      ],
      queryFn: async ({ pageParam = 1 }) =>
        fetchProductionsWithMedia({ limit, page: pageParam, filters: transformedFilters }, cookies),
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getProductionDescriptions: ({ id, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_PRODUCTION_DESCRIPTIONS', `${id}`],
    queryFn: async () => fetchProductionDescriptions(id, cookies),
    select: response => response?.data,
    ...defaultConfig,
    ...queryConfig,
  }),
  getAboutImages: ({ id, limit = 5, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_PRODUCTION_ABOUT_IMAGES', `${id}`, `${limit}`],
    queryFn: async () => fetchAboutMedia({ id, limit }, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getProductionBooklet: ({ id, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_PRODUCTION_BOOKLETS', `${id}`],
    queryFn: async () => fetchProductionBooklets(id, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getProductionSynopsis: ({ id, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_PRODUCTION_SYNOPSIS', `${id}`],
    queryFn: async () => fetchProductionSyopsis(id, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getProductionAggregations: (
    { entityType, entityId, query = '', aggregationType, queryFilters, queryConfig = {}, limit = 10 },
    cookies,
    language,
  ) => ({
    queryKey: [
      getLanguage(language),
      'GET_PRODUCTION_AGGREGATIONS',
      `${entityType}`,
      `${entityId}`,
      `${aggregationType}`,
      `${query}`,
      queryFilters,
      limit,
    ],
    queryFn: async () =>
      fetchProductionAggregations({ entityType, entityId, query, aggregationType, queryFilters, limit }, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getSpotlightProductions: (
    { countryId = '', type, filters, limit = 10, queryConfig = {} } = {},
    cookies,
    language,
  ) => {
    const transformedFilters = {
      ...transformFiltersToParams(filters),
      date_from: createDate().format('YYYY-MM-DD'),
      sort: 'year',
      added_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
      approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
      ...(countryId && { country_id: countryId }),
      ...(type === SPOTLIGHT_PRODUCTION_TYPE.NEW_RARE && { is_new_and_rare: true }),
      ...(type === SPOTLIGHT_PRODUCTION_TYPE.HIGHLIGHTS && { is_highlight: true }),
    };

    return {
      queryKey: [getLanguage(language), 'GET_SPOTLIGHT_PRODUCTIONS', `${limit}`, JSON.stringify(transformedFilters)],
      queryFn: async ({ pageParam = 1 }) =>
        fetchSpotlightProductions({ page: pageParam, filters: transformedFilters, limit }, cookies),
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getAggregationList: ({ endpoint, queryParams, pagination, queryConfig = {} }, cookies, language) => {
    const query = {
      queryParams,
      pagination: { limit: 20 },
    };

    return {
      queryKey: [getLanguage(language), 'GET_AGGREGATIONS', queryParams],
      queryFn: async () => getList(endpoint, query, pagination, cookies),
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getProductionCasting: getQuery('GET_CASTING_RESULTS', fetchProductionsCasting),
  createProductionRevival: postQuery(PRODUCTIONS_REVIVALS),
};

export default queries;
