/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import set from 'lodash/set';
import first from 'lodash/first';
import keyBy from 'lodash/keyBy';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import toArray from 'lodash/toArray';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import { createSelectFieldOptions, getCityWithAreasLabel, getOption } from 'utils/globals/app';
import { getArtisLabelNameWithRole } from 'containers/Artist/utils';
import { updateMediaStructure } from 'utils/globals/deprecate';
import {
  CHOIR_PROFESSION_TYPE_ID,
  INSTRUMENTALIST_PROFESSION_TYPE_ID,
  ORCHESTRA_PROFESSION_TYPE_ID,
  PRODUCTION_DOCUMENT_TYPES,
  SINGER_PROFESSION_TYPE_ID,
  VIDEO_ATTRIBUTE_TYPES_IDS,
} from 'constants/deprecate';

import { getWorkTitle } from 'utils/works';
import { getComposersFromCreators } from 'utils/composer';
import { getWorkRolePrimaryProfession } from 'utils/common';
import { initialState } from './reducer';
import { cardFields } from './constants';
import {
  getPerformanceCastCrewOptions,
  getPerformancesFormValues,
  getRolesWithUpdatedName,
  getValueOrRef,
} from './utils';
import { createDate } from '../../utils/date';

const getAttributeName = (media, attrTypeId, field = 'name') =>
  get(find(media?.attributes, { attributeType: { id: attrTypeId } }), field);

const selectPerformancesDomain = state => state.ProductionUpdate || initialState;

const selectProduction = () => createSelector(selectPerformancesDomain, subState => get(subState, 'production.entity'));
const selectProductionUpdating = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'production.loading'));

const selectLoadingFieldName = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'loadingFieldName'));

const selectCompaniesOptions = () =>
  createSelector(selectPerformancesDomain, subState =>
    createSelectFieldOptions(get(subState, 'companies.data', []), 'id', 'name', {}, true),
  );

const selectOrganizationsOptionsLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'orgsLoading'));

const selectOrganizationsOptions = () =>
  createSelector(selectPerformancesDomain, subState =>
    createSelectFieldOptions(get(subState, 'organization.data', []), 'id', 'name', {}, true),
  );

const selectArtistProfessionsOptions = allProfession =>
  createSelector(selectPerformancesDomain, subState => {
    let professions = get(subState, 'professions.data', []);

    if (!allProfession) {
      professions = professions?.filter(prof => prof.slug !== 'singer');
    }
    return createSelectFieldOptions(professions, 'id', 'name', { type: 'profession' }, true);
  });

const selectArtistRolesOptions = () =>
  createSelector(selectPerformancesDomain, subState => {
    const roles = get(subState, 'roles.data', []);
    return roles?.map(r => {
      let professionName = getWorkRolePrimaryProfession(r?.workRoleProfessions)?.profession?.name ?? '';

      if (!professionName && r?.profession) {
        professionName = r?.profession?.name;
      }

      if (professionName) {
        professionName = ` (${professionName})`;
      }

      return {
        ...r,
        value: r?.id,
        label: `${r?.name}${professionName}`,
        type: 'role',
      };
    });
  });

const selectCanonicalRoles = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'roles.data', []));

const selectArtistNamesOptions = () =>
  createSelector(selectPerformancesDomain, subState => {
    const names = get(subState, 'names.data', []);
    return names.map(name => ({
      value: name?.id,
      label: getArtisLabelNameWithRole(name),
      type: 'name',
      url: name?.image?.small,
    }));
  });

const selectArtistNamesOptionsLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'namesLoading'));

const selectProductionUsedRolesAndProfessions = () =>
  createSelector(selectPerformancesDomain, subState => {
    const professions = [];
    const roles = [];
    const professionsOptions = {};
    const rolesOptions = {};
    const productionCast = get(subState, 'casts.data', []);
    const getProfleOption = castRecord => {
      const profileName = castRecord?.profile?.name || castRecord?.profileReference;
      return { ...castRecord, label: profileName, value: castRecord?.profile?.id || profileName };
    };
    // add ref for preventing role with name 123 to be an id of type number
    productionCast.forEach(castItem => {
      const workRole = getValueOrRef(castItem, 'workRole', 'workRoleReference');
      const profession = getValueOrRef(castItem, 'profession', 'professionReference');
      if (workRole?.id) {
        roles.push(workRole);
        set(rolesOptions, workRole?.id, [...get(rolesOptions, workRole?.id, []), getProfleOption(castItem)]);
      } else if (profession?.id) {
        professions.push(profession);
        set(professionsOptions, profession?.id, [
          ...get(professionsOptions, profession?.id, []),
          getProfleOption(castItem),
        ]);
      }
    });

    return { professions: uniqBy(professions, 'id'), roles: uniqBy(roles, 'id'), professionsOptions, rolesOptions };
  });

const selectPerformancesFormValues = () =>
  createSelector(selectProduction(), selectPerformancesDomain, (production, subState) => {
    const [performances, digitalPerformances] = partition(get(subState, 'dates.data', []), { mode: 'stage' });
    if (get(subState, 'dates.loadedOnce', false)) {
      const groupedValues = groupBy(performances, 'date');
      const valuesToSet = {};
      Object.keys(groupedValues).forEach(date => {
        const firstPerf = get(groupedValues, `${date}[0]`, {});
        const commonPerformance = getPerformancesFormValues(production, date, firstPerf);
        (groupedValues[date] || []).forEach(p => {
          const { professionsOptions, rolesOptions } = getPerformanceCastCrewOptions(p?.casts);
          commonPerformance[cardFields.time.name].push({
            time: p?.time,
            cast: rolesOptions,
            crew: professionsOptions,
            id: p?.id,
          });
          if (p?.time === null && get(groupedValues, date, []).length === 1) {
            commonPerformance.cast = rolesOptions;
            commonPerformance.crew = professionsOptions;
          }
        });
        set(valuesToSet, date, commonPerformance);
      });
      return { values: valuesToSet, digital: digitalPerformances };
    }
    return { values: null, digital: null };
  });

const selectLivestreams = () =>
  createSelector(selectPerformancesDomain, subState => {
    const livestreams = get(subState, initialState.livestreams.listName, {});
    const sortedData = livestreams?.data?.sort((lsa, lsb) => {
      if (
        getAttributeName(lsa, VIDEO_ATTRIBUTE_TYPES_IDS.START_DATE) >
        getAttributeName(lsb, VIDEO_ATTRIBUTE_TYPES_IDS.START_DATE)
      ) {
        return -1;
      }
      return 1;
    });
    return { ...livestreams, data: sortedData };
  });

const selectLivesEvents = () =>
  createSelector(selectProduction(), selectPerformancesDomain, (production, subState) => {
    const dates = get(subState, 'dates.data', []);
    // add grouped by date
    return sortBy(dates, 'date');
  });

const selectCastStepFormValues = () =>
  createSelector(selectPerformancesDomain, subState => {
    const casts = get(subState, 'casts.data', []);
    const setArtistsArray = arr => arr.map(item => ({ ...item, artists: [] }));
    const allProfessions = keyBy(
      setArtistsArray(
        get(subState, 'professions.data', []).filter(
          prof =>
            ![
              SINGER_PROFESSION_TYPE_ID,
              ORCHESTRA_PROFESSION_TYPE_ID,
              CHOIR_PROFESSION_TYPE_ID,
              INSTRUMENTALIST_PROFESSION_TYPE_ID,
            ].includes(prof?.id),
        ),
      ),
      'id',
    );
    const allRoles = keyBy(setArtistsArray(getRolesWithUpdatedName(get(subState, 'roles.data', []))), 'id');

    const setArtists = (castArray, professionId, item) => {
      const fieldName = `${professionId}.artists`;
      set(castArray, fieldName, [
        ...get(castArray, fieldName, []),
        getOption(item, 'profile.id', 'profile.name', {}, true),
      ]);
    };
    // for roles & professions
    casts.forEach(castItem => {
      if (!castItem?.profile?.id) return; // to filter casts without profile for roles and professions

      const workRole = getValueOrRef(castItem, 'workRole', 'workRoleReference');
      const profession = getValueOrRef(castItem, 'profession', 'professionReference');
      if (workRole?.id) {
        const roleId = workRole?.id;
        //  if custom role is not set yet
        if (!get(allRoles, roleId)) {
          set(allRoles, roleId, { ...workRole, initName: workRole?.name });
        }
        // add artist to the list
        setArtists(allRoles, roleId, castItem);
      } else if (
        profession?.id &&
        ![
          SINGER_PROFESSION_TYPE_ID,
          ORCHESTRA_PROFESSION_TYPE_ID,
          CHOIR_PROFESSION_TYPE_ID,
          INSTRUMENTALIST_PROFESSION_TYPE_ID,
        ].includes(profession.id)
      ) {
        const professionId = profession?.id;
        if (!get(allProfessions, professionId)) {
          set(allProfessions, professionId, profession);
        }
        setArtists(allProfessions, professionId, castItem);
      }
    });
    const profArray = toArray(allProfessions);
    const rolesArray = sortBy(toArray(allRoles), 'roleOrder');
    const getOrchestraChoirOptions = professionTypeId =>
      casts
        .filter(el => el?.profession?.id === professionTypeId)
        .map(orc => getOption(orc, 'profileReference', 'profileReference', {}, true));
    const orchestras = getOrchestraChoirOptions(ORCHESTRA_PROFESSION_TYPE_ID);
    const choirs = getOrchestraChoirOptions(CHOIR_PROFESSION_TYPE_ID);
    return {
      professions: profArray,
      roles: rolesArray,
      orchestras: orchestras.length ? orchestras : [undefined],
      choirs: choirs.length ? choirs : [undefined],
    };
  });

const selectVenuesOptions = () =>
  createSelector(selectPerformancesDomain, subState =>
    get(subState, 'venues.data', []).map(venue => ({
      ...venue,
      value: venue?.id,
      label: `${venue?.name} (${venue?.city?.name || ''})`,
    })),
  );

const selectCasts = () => createSelector(selectPerformancesDomain, subState => get(subState, 'casts.data', []));

const selectCastsLoadedState = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'casts.loadedOnce', []));

const selectCastsLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'casts.loading', false));

const selectProductionCastDataLoaded = () =>
  createSelector(
    selectPerformancesDomain,
    subState =>
      (get(subState, 'casts.loadedOnce', false) || get(subState, 'casts.error', null)) &&
      !get(subState, 'casts.loading', false) &&
      (get(subState, 'professions.loadedOnce', false) || get(subState, 'professions.error', null)) &&
      !get(subState, 'professions.lading', false) &&
      (get(subState, 'roles.loadedOnce', false) || get(subState, 'roles.error', null)) &&
      !get(subState, 'roles.loading', false),
  );

const selectProductionImages = () =>
  createSelector(selectPerformancesDomain, subState => {
    const images = get(subState, 'images', {});
    return { loading: images?.loading, data: updateMediaStructure(images?.data) };
  });

const selectProductionVideo = () =>
  createSelector(selectPerformancesDomain, subState => {
    const video = get(subState, 'video', {});
    return { loading: video?.loading, data: updateMediaStructure(video?.data) };
  });

const selectDates = () => createSelector(selectPerformancesDomain, subState => get(subState, 'dates.data', []));

const selectDatesLoadedState = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'dates.loadedOnce', []));

const selectDatesLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'dates.loading', []));

const selectDatesUpdating = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'datesUpdating', false));

const selectArtistDates = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'artistProductionDates.data[0]', [])); // always 1 item with all data

const selectArtistDatesLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'artistProductionDates.loading', []));

const selectArtistDatesLoadedState = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'artistProductionDates.loadedOnce', []));

const selectArtistsSuggestions = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'artistsSuggestions.data', []));

const selectProductionDates = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'dates.data', []));

const selectMediaAttributesOptions = type =>
  createSelector(selectPerformancesDomain, subState =>
    createSelectFieldOptions(
      get(subState, 'mediaAttributes.data', []).filter(atr => atr?.attributeType?.id === type),
      'id',
      'name',
    ),
  );

const selectFestivalsOptions = () =>
  createSelector(selectPerformancesDomain, subState => ({
    options: createSelectFieldOptions(get(subState, 'festivals.data', []), 'id', 'name', {}, true),
    fieldName: get(subState, 'loadingFieldName', ''),
  }));

const selectPerformanceCitiesOptions = () =>
  createSelector(selectPerformancesDomain, subState =>
    createSelectFieldOptions(get(subState, 'cities.data', []), 'id', getCityWithAreasLabel),
  );

const selectPerformanceStagesOptions = () =>
  createSelector(selectPerformancesDomain, subState =>
    createSelectFieldOptions(get(subState, 'stages.data', []), 'id', 'name'),
  );

const selectWorksOptionsData = () =>
  createSelector(selectPerformancesDomain, subState => ({
    options: get(subState, 'works.data', []).map(work => ({
      ...work,
      value: work?.id,
      label: getWorkTitle(work, true, true),
      workName: work?.original_name || get(work, 'name', ''),
      workType: work?.workType,
      composers: getComposersFromCreators(work.creators),
    })),
    loading: get(subState, 'works.loading', []),
  }));

const selectProductionCompanyVenues = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'companyVenues', {}));

const selectProductionSynopsis = () =>
  createSelector(selectPerformancesDomain, subState => {
    const allFiles = get(subState, 'documents.data', []);
    const groupedDocuments = toArray(groupBy(allFiles, 'language.id'));
    const documents = groupedDocuments.map(doc => {
      const groupedData = groupBy(doc, 'document_type');
      return {
        synopsis: first(get(groupedData, PRODUCTION_DOCUMENT_TYPES.SYNOPSIS)),
        acts: sortBy(get(groupedData, PRODUCTION_DOCUMENT_TYPES.ACT), 'act_number'),
      };
    });
    return {
      loadedOnce: get(subState, 'documents.loadedOnce', false),
      loading: get(subState, 'documents.loading', false),
      documents,
    };
  });

const selectNewProductionDescriptions = () =>
  createSelector(selectPerformancesDomain, subState => ({
    data: get(subState, 'descriptions.data', [])?.filter(
      record => get(record, 'documentType') === PRODUCTION_DOCUMENT_TYPES.DESCRIPTION,
    ),
    changes: get(subState, 'descriptions.changes', []),
  }));

const selectNewProductionDescriptionsLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'descriptions.loading', false));

const selectNewProductionSynopsis = () =>
  createSelector(selectPerformancesDomain, subState => ({
    data: get(subState, 'synopsis.data', []),
    changes: get(subState, 'synopsis.changes', []),
  }));

const selectNewProductionSynopsisLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'synopsis.loading', false));

const selectProductionBooklets = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'booklets', []));

const selectNewProductionBooklets = () =>
  createSelector(selectPerformancesDomain, subState => ({
    data: get(subState, 'documents.data', [])?.filter(
      record => get(record, 'documentType') === PRODUCTION_DOCUMENT_TYPES.BOOKLET,
    ),
    changes: get(subState, 'documents.changes', []),
  }));

const selectNewProductionBookletsLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'documents.loading', false));

const selectComposersOptions = () =>
  createSelector(selectPerformancesDomain, subState => ({
    options: get(subState, 'composers.data', [])?.map(composer => ({
      ...composer,
      label: composer?.name || `${composer?.firstName ?? ''} ${composer?.lastName ?? ''}`,
      value: composer?.id,
    })),
    loading: get(subState, 'composers.loading', false),
  }));

const selectProductionVideoOnDemandData = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, initialState.videoOnDemand.listName, []));

const selectProductionVideoCostTypesOptions = () =>
  createSelector(selectPerformancesDomain, subState =>
    createSelectFieldOptions(get(subState, `${initialState.videoCostTypes.listName}.data`, []), 'id', 'name', {}, true),
  );

const selectVideoCostTypesLoadedState = () =>
  createSelector(selectPerformancesDomain, subState =>
    get(subState, `${initialState.videoCostTypes.listName}.loadedOnce`, false),
  );

const selectVideoFormatsNames = () =>
  createSelector(selectPerformancesDomain, subState =>
    get(subState, `${initialState.videoFormats.listName}.data`, [])?.map(format => format?.slug),
  );

const selectImageFormatsNames = () =>
  createSelector(selectPerformancesDomain, subState =>
    get(subState, `${initialState.imageFormats.listName}.data`, [])?.map(format => format?.slug),
  );

const selectReviews = () =>
  createSelector(selectPerformancesDomain, subState => ({
    data: get(subState, `${initialState.reviews.listName}.data`, []),
    changes: get(subState, `${initialState.reviews.listName}.changes`, []),
  }));

const selectReviewsLoading = () =>
  createSelector(selectPerformancesDomain, subState =>
    get(subState, `${initialState.reviews.listName}.loading`, false),
  );

const selectProductionDatesV1 = () =>
  createSelector(selectPerformancesDomain, subState =>
    get(subState, `${initialState.productionDatesV1.listName}.data`, []),
  );

const selectProductionCalendarDates = () =>
  createSelector(selectPerformancesDomain, subState =>
    get(subState, `${initialState.calendarDates.listName}.data`, []),
  );

const selectProductionCalendarDatesLoading = () =>
  createSelector(selectPerformancesDomain, subState =>
    get(subState, `${initialState.calendarDates.listName}.loading`, false),
  );

const selectProductionCalendarDatesLoadedOnes = () =>
  createSelector(selectPerformancesDomain, subState =>
    get(subState, `${initialState.calendarDates.listName}.loadedOnce`, false),
  );

const selectDatesV1Loading = () =>
  createSelector(selectPerformancesDomain, subState =>
    get(subState, `${initialState.productionDatesV1.listName}.loading`, false),
  );

const selectCastAndCrew = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'castAndCrew.data', []));

const selectReviewDetails = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, initialState.reviewDetails.entity, {}));

const selectWorksForProduction = () => createSelector(selectProduction(), subState => get(subState, 'works', []));

const selectPerormanceDates = () =>
  createSelector(selectPerformancesDomain, subState => {
    const performancesWithMonth = [];
    const dates = get(subState, 'dates.data', []);
    const sortedDates = sortBy(dates, 'startDate');
    sortedDates.forEach(date => {
      performancesWithMonth.push({
        ...date,
        yearMonth: date?.startDate ? createDate(date?.startDate).format('MMM YY') : null,
        date: date?.startDate ? createDate(date?.startDate).format('DD') : null,
        month: date?.startDate ? createDate(date?.startDate).format('MMM') : null,
      });
    });
    const groupedDates = groupBy(performancesWithMonth, 'yearMonth');
    return groupedDates;
  });

const selectProductionWorks = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'productionWorks.data', []));

const selectProductionWorksLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'productionWorks.loading', false));

const selectPerformanceLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'performances.loading', false));

const selectWorkCastCrewLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'workCastCrew.loading', false));

const selectPerformanceImages = () =>
  createSelector(selectPerformancesDomain, subState => {
    const images = get(subState, 'performanceImages', {});
    return { loading: images?.loading, data: updateMediaStructure(images?.data) };
  });

const selectPerformanceVideos = () =>
  createSelector(selectPerformancesDomain, subState => {
    const video = get(subState, 'performanceVideos', {});
    return { loading: video?.loading, data: updateMediaStructure(video?.data) };
  });

const selectWorkStageTypes = () =>
  createSelector(selectPerformancesDomain, subState =>
    createSelectFieldOptions(get(subState, 'workStageTypes.data', []), 'id', 'name'),
  );

const selectWorkStageTypesLoading = () =>
  createSelector(selectPerformancesDomain, subState => get(subState, 'workStageTypes.loading', false));

export {
  selectPerformancesDomain,
  selectProduction,
  selectLoadingFieldName,
  selectCompaniesOptions,
  selectOrganizationsOptions,
  selectOrganizationsOptionsLoading,
  selectArtistProfessionsOptions,
  selectArtistRolesOptions,
  selectArtistNamesOptions,
  selectArtistNamesOptionsLoading,
  selectPerformancesFormValues,
  selectVenuesOptions,
  selectCasts,
  selectDates,
  selectArtistDates,
  selectArtistsSuggestions,
  selectProductionImages,
  selectProductionVideo,
  selectMediaAttributesOptions,
  selectProductionUsedRolesAndProfessions,
  selectFestivalsOptions,
  selectPerformanceCitiesOptions,
  selectPerformanceStagesOptions,
  selectProductionUpdating,
  selectCastsLoading,
  selectDatesLoading,
  selectCastStepFormValues,
  selectArtistDatesLoading,
  selectProductionCompanyVenues,
  selectWorksOptionsData,
  selectProductionSynopsis,
  selectProductionBooklets,
  selectLivestreams,
  selectLivesEvents,
  selectComposersOptions,
  selectProductionCastDataLoaded,
  selectProductionVideoOnDemandData,
  selectProductionVideoCostTypesOptions,
  selectVideoFormatsNames,
  selectImageFormatsNames,
  selectDatesLoadedState,
  selectCastsLoadedState,
  selectVideoCostTypesLoadedState,
  selectArtistDatesLoadedState,
  selectDatesUpdating,
  selectCanonicalRoles,
  selectReviews,
  selectReviewsLoading,
  selectReviewDetails,
  selectWorksForProduction,
  selectNewProductionDescriptions,
  selectNewProductionDescriptionsLoading,
  selectNewProductionSynopsis,
  selectNewProductionSynopsisLoading,
  selectNewProductionBooklets,
  selectNewProductionBookletsLoading,
  selectPerormanceDates,
  selectProductionWorks,
  selectProductionWorksLoading,
  selectPerformanceLoading,
  selectWorkCastCrewLoading,
  selectProductionDatesV1,
  selectDatesV1Loading,
  selectProductionCalendarDates,
  selectProductionCalendarDatesLoading,
  selectProductionCalendarDatesLoadedOnes,
  selectProductionDates,
  selectCastAndCrew,
  selectPerformanceImages,
  selectPerformanceVideos,
  selectWorkStageTypes,
  selectWorkStageTypesLoading,
};
