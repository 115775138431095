const convertNoIndexURL = url => url.replace(/([^/]+)$/, 'noindex-$1');
const noIndexImageTransformer = image =>
  Object.fromEntries(
    Object.entries(image || {}).map(([key, value]) => [key, key === 'blurHash' ? value : convertNoIndexURL(value)]),
  );

export const transformImagesWithNoIndex = ({ data = [] } = {}) => {
  const transformedData = data.map(item => {
    if (item?.artist?.image) {
      return {
        ...item,
        artist: {
          ...item.artist,
          image: noIndexImageTransformer(item.artist.image),
        },
      };
    }
    if (item?.image) {
      return {
        ...item,
        image: noIndexImageTransformer(item.image),
      };
    }
    if (item?.logo) {
      return {
        ...item,
        logo: noIndexImageTransformer(item.logo),
      };
    }
    return item;
  });

  return {
    ...data,
    data: transformedData,
  };
};
