import { createRoutine } from 'redux-saga-routines';

import {
  CHANGE_PASSWORD,
  REACTIVATE_SUBSCRIPTION,
  GET_PRODUCTS,
  GET_USER_PAYMENTS,
  UPDATE_SUBSCRIPTION,
  GET_ALL_PRODUCTS,
  GET_ARTISTS_WORKED_WITH,
  GET_GIFT_SUBSCRIPTIONS,
  GET_USER_REFERRALS,
  GET_USER_REWARD_BALANCE,
} from './constants';

export const changePasswordAction = createRoutine(CHANGE_PASSWORD);
export const getUserPaymentsAction = createRoutine(GET_USER_PAYMENTS);
export const getProductsAction = createRoutine(GET_PRODUCTS);
export const getAllProductsAction = createRoutine(GET_ALL_PRODUCTS);
export const reactivateSubscriptionAction = createRoutine(REACTIVATE_SUBSCRIPTION);
export const updateSubscriptionAction = createRoutine(UPDATE_SUBSCRIPTION);
export const getArtistsWorkedWithAction = createRoutine(GET_ARTISTS_WORKED_WITH);
export const getGiftSubscriptionsAction = createRoutine(GET_GIFT_SUBSCRIPTIONS);
export const getUserReferralsAction = createRoutine(GET_USER_REFERRALS);
export const getUserRewardBalance = createRoutine(GET_USER_REWARD_BALANCE);
